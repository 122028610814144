/* eslint-disable no-case-declarations */
/// <reference lib="webworker" />
/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { clientsClaim } from 'workbox-core';
import { ExpirationPlugin } from 'workbox-expiration';
import { precacheAndRoute, createHandlerBoundToURL } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { StaleWhileRevalidate } from 'workbox-strategies';

declare const self: ServiceWorkerGlobalScope;

interface PushSubscriptionPayload<T = any> {
  type:
  | 'SKIP_WAITING'
  | 'PUSH_SUBSCRIBED'
  | 'PUSH_SUBSCRIPTION_STATE'
  | 'PUSH_SUBSCRIPTION_REQ'
  | 'PUSH_SUBSCRIPTION_CHECK'
  | 'PUSH_SUBSCRIPTION_OK';
  value: T;
}

interface PushPayload {
  title: string;
  message?: string;
  meta?: any;
}

clientsClaim();

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
// eslint-disable-next-line no-underscore-dangle
precacheAndRoute(self.__WB_MANIFEST);

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');
registerRoute(
  // Return false to exempt requests from being fulfilled by index.html.
  ({ request, url }: { request: Request; url: URL }) => {
    // If this isn't a navigation, skip.
    if (request.mode !== 'navigate') {
      return false;
    }

    // ignore ^/files endpoints
    if (url.pathname.match(/(files|graphql|api)/ig)) {
      return false;
    }

    // If this is a URL that starts with /_, skip.
    if (url.pathname.startsWith('/_')) {
      return false;
    }

    // If this looks like a URL for a resource, because it contains
    // a file extension, skip.
    if (url.pathname.match(fileExtensionRegexp)) {
      return false;
    }

    // Return true to signal that we want to use the handler.
    return true;
  },
  createHandlerBoundToURL(`${process.env.PUBLIC_URL}/index.html`)
);

// An example runtime caching route for requests that aren't handled by the
// precache, in this case same-origin .png requests like those from in public/
registerRoute(
  // Add in any other file extensions or routing criteria as needed.
  ({ url }) => url.origin === self.location.origin && url.pathname.endsWith('.png'),
  // Customize this strategy as needed, e.g., by changing to CacheFirst.
  new StaleWhileRevalidate({
    cacheName: 'images',
    plugins: [
      // Ensure that once this runtime cache reaches a maximum size the
      // least-recently used images are removed.
      new ExpirationPlugin({ maxEntries: 50 }),
    ],
  })
);

// This allows the web app to trigger skipWaiting via
// registration.waiting.postMessage({type: 'SKIP_WAITING'})
self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

// Any other custom service worker logic can go here.

// Push notifications
const urlBase64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

const talkClient = <T extends unknown = any>(payload: PushSubscriptionPayload<T>) => {
  self.clients
    .matchAll({
      includeUncontrolled: true,
      type: 'window',
    })
    .then((clients) => {
      // Send a response - the clients array is ordered by last focused
      if (clients && clients.length) {
        clients[0].postMessage(payload);
      }
    });
};

self.addEventListener('message', async (event: ExtendableMessageEvent) => {
  const graphQLEndpoint = `${process.env.REACT_APP_GRAPHQL_URL}/graphql`;
  const data = event.data as PushSubscriptionPayload;
  if (data) {
    // eslint-disable-next-line default-case
    switch (data.type) {
      case 'SKIP_WAITING':
        self.skipWaiting().then(
          async () => {
            self.clients.matchAll({
              type: 'window',
              includeUncontrolled: true,
            }).then((clientList) => {
              // eslint-disable-next-line no-restricted-syntax
              for (const client of clientList) {
                client.postMessage('reload');
              }
            });
          }
        );
        break;
      case 'PUSH_SUBSCRIPTION_CHECK':
        self.registration.pushManager.getSubscription().then((sub: any) => {
          console.log({ sub });
          talkClient({ type: 'PUSH_SUBSCRIPTION_STATE', value: !!sub });
        });
        break;
      case 'PUSH_SUBSCRIPTION_REQ':
        console.log('[Sw] Registering user into push notification', [data.value]);
        const { token } = data.value;

        // 1. request for VAPID keys
        const { publicKey } = await fetch(graphQLEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },

          body: JSON.stringify({
            query: `mutation {
              generateVapidKeys {
                publicKey
              }
            }`,
          }),
        })
          .then((res) => res.json())
          .then((r) => r.data.generateVapidKeys);

        // 2. create user subscription
        const subscription = await self.registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicKey),
        });

        // 3. save subscription data
        const response = await fetch(graphQLEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },

          body: JSON.stringify({
            query: `mutation SubscribeWebPush($subscription: JSON!) {
              subscribeWebPush(subscription: $subscription) {
                vapid {
                  publicKey
                }
                subscription
              }
            }`,
            variables: {
              subscription: {
                ...subscription.toJSON(),
                device: navigator.userAgent,
              },
            },
          }),
        })
          .then((res) => res.json())
          .then((res) => res.data.subscribeWebPush);

        talkClient({ type: 'PUSH_SUBSCRIPTION_OK', value: response });
        break;
    }
  }
});

self.addEventListener('push', (e) => {
  let body: any;

  if (e.data) {
    body = e.data.json() as PushPayload;
  } else {
    body = 'Push message no payload';
  }

  const actions: any[] = [
    {
      action: 'close',
      title: 'Close',
      icon: 'images/xmark.png',
    },
  ];

  const options: any = {
    icon: 'https://content.app-sources.com/s/6085942132755771/uploads/Images/96_96_w-6723354.png',
    vibrate: [100, 50, 100],
    actions,
  };

  if (typeof body === 'object') {
    const { title, message, meta, tag } = body;

    options.body = message || 'Something happened in your workspace';
    options.data = meta;

    if (tag) {
      options.tag = tag;
    }

    if (meta?.link) {
      options.actions.push({
        action: 'explore',
        title: 'Follow The Link',
        icon: 'images/checkmark.png',
      });
    }

    e.waitUntil(self.registration.showNotification(`WAU I/O: ${title}`, options));
  }
});

self.addEventListener('notificationclick', (event: NotificationEvent) => {
  event.notification.close();

  switch (event.action) {
    case 'close':
      // do nothing, notification has been closed before
      return;
    default:
    case 'explore':
      if (event.notification.data?.link) {
        const link = new URL(event.notification.data?.link, self.location.origin).href;

        const promiseChain = self.clients
          .matchAll({
            type: 'window',
            includeUncontrolled: true,
          })
          .then((windowClients) => {
            let matchingClient = null;

            for (let i = 0; i < windowClients.length; i++) {
              const windowClient = windowClients[i];
              if (windowClient.url === link) {
                matchingClient = windowClient;
                break;
              }
            }

            if (matchingClient) {
              return matchingClient.focus();
            }
            return self.clients.openWindow(link);
          });

        event.waitUntil(promiseChain);
      }
      break;
  }
});
